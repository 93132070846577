import tokens from "./tokens";
import addresses from "constants/addresses";

export const liquidityList = [
  {
    pid: 0,
    lpSymbol: tokens.wild.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wild.address,
    decimals: 18,
    logoA: tokens.wild.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.eth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.eth.address,
    decimals: 18,
    logoA: tokens.eth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.wpls.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wpls.address,
    decimals: 18,
    logoA: tokens.wpls.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WPLS-pWiLD",
    isTokenOnly: false,
    lpAddresses: addresses.wildWplslp,
    decimals: 18,
    logoA: tokens.wild.logo,
    logoB: tokens.wpls.logo,
  },
];

export const zapList = [
  {
    pid: 0,
    lpSymbol: tokens.wild.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wild.address,
    decimals: 18,
    logoA: tokens.wild.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.eth.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.eth.address,
    decimals: 18,
    logoA: tokens.eth.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.wpls.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wpls.address,
    decimals: 18,
    logoA: tokens.wpls.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.usdc.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.usdc.address,
    decimals: 6,
    logoA: tokens.usdc.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.dai.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.dai.address,
    decimals: 18,
    logoA: tokens.dai.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.usdt.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.usdt.address,
    decimals: 18,
    logoA: tokens.usdt.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WPLS-pWiLD",
    isTokenOnly: false,
    lpAddresses: addresses.wildWplslp,
    decimals: 18,
    logoA: tokens.wild.logo,
    logoB: tokens.wpls.logo,
  },
];

const farmsConfig = [
  {
    pid: 0,
    lpSymbol: "WPLS-pWiLD",
    lpAddresses: addresses.wildWplslp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.wild,
    quoteToken: tokens.wpls,
    logoA: tokens.wild.logo,
    logoB: tokens.wpls.logo,
  },
  {
    pid: 1,
    lpSymbol: "WPLS-USDC",
    lpAddresses: addresses.usdcLp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.usdc,
    quoteToken: tokens.wpls,
    logoA: tokens.wpls.logo,
    logoB: tokens.usdc.logo,
  },
  {
    pid: 2,
    lpSymbol: "WPLS",
    lpAddresses: addresses.wpls,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.wpls,
    quoteToken: tokens.wpls,
    logoA: tokens.wpls.logo,
    logoB: tokens.wpls.logo,
  },
  {
    pid: 3,
    lpSymbol: "DAI",
    lpAddresses: addresses.dai,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.dai,
    quoteToken: tokens.dai,
    logoA: tokens.dai.logo,
    logoB: tokens.dai.logo,
  },
  {
    pid: 4,
    lpSymbol: "USDC",
    lpAddresses: addresses.usdc,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.usdc,
    quoteToken: tokens.usdc,
    logoA: tokens.usdc.logo,
    logoB: tokens.usdc.logo,
  },
  {
    pid: 5,
    lpSymbol: "USDT",
    lpAddresses: addresses.usdt,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    logoA: tokens.usdt.logo,
    logoB: tokens.usdt.logo,
  },
  {
    pid: 6,
    lpSymbol: "pWiLDNFT",
    lpAddresses: addresses.nft,
    isTokenOnly: true,
    isNFTPool: true,
    token: tokens.nft,
    quoteToken: tokens.wild,
    logoA: "/assets/tokens/nft.png",
    logoB: "",
  },
];

export default farmsConfig;
