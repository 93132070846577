const contractAddresses = {
  masterChef: "0x90B3e0853C251aFb28d0D7c44e72fc16Ee344786",
  multiCall: "0xb00310683Ee98dca887e2e6AEfe6510F085802e1",
  zap: "0xd88927A667e9883e101CEf509A8Da100F00c71fd",
  router: "0x165C3410fC91EF562C50559f7d2289fEbed552d9",
  factory: "0x29eA7545DEf87022BAdc76323F373EA1e707C523",
  wild: "0xFeD150f75A70c519DB81ae02F0a9eC264D094eF4",
  wpls: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
  dai: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
  usdt: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
  usdc: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
  nft: "0xB258c44E1544e7d91AbC9A617252Ee2c94D8d542",
  usdcLp: "0x8ebe62d5e9d26b637673d91f56900233d6a4910d",
  wildWplslp: "0x812c12e8f1d4eb0addadfd77a4f7a1d7e686db39",
};

export default contractAddresses;
